import Employee from './Employee';
import { React } from 'react';

function EmployeeDisplay() {
	const employeeList = [
		{
			id: 39,
			avatar: 'https://api.dsshr.site/storage/avatars/1668738426.jpeg',
			employeeId: '0039',
			enName: 'KIM IN',
			mmName: 'KIM IN',
			position: 'Founder'
		},
		{
			id: 26,
			avatar: 'https://api.dsshr.site/storage/avatars/1643944607.jpeg',
			employeeId: '0026',
			enName: 'James Kim',
			mmName: 'James Kim',
			position: 'CEO'
		},
		{
			id: 28,
			avatar: 'https://api.dsshr.site/storage/avatars/1643944799.jpeg',
			employeeId: '0028',
			enName: 'Mia Kim',
			mmName: 'Mia Kim',
			position: 'Chief Creative Officer'
		},
		{
			id: 41,
			avatar: 'https://api.dsshr.site/storage/avatars/1676877048.jpg',
			employeeId: '0041',
			enName: 'Pomba Magar',
			mmName: '\u1015\u103d\u1014\u103a\u1018\u102c\u1019\u102c\u1002\u102b',
			position: 'Head of Development'
		},
		{
			id: 24,
			avatar: 'https://api.dsshr.site/storage/avatars/1639722537.jpg',
			employeeId: '0024',
			enName: 'Aung Phyo Htet',
			mmName: '\u1021\u1031\u102c\u1004\u103a\u1016\u103c\u102d\u102f\u1038\u1011\u1000\u103a',
			position: 'Project Manager'
		},
		{
			id: 30,
			avatar: 'https://api.dsshr.site/storage/avatars/1643945208.jpg',
			employeeId: '0030',
			enName: 'Thinn Pyant  Moe Oo',
			mmName:
				'\u101e\u1004\u103a\u1038\u1015\u103b\u1036\u1037\u1019\u102d\u102f\u1038\u1025\u102e\u1038',
			position: 'HR Manager'
		}
	];

	return (
		<div>
			<section id="team" className="section">
				<div className="container">
					<div className="section-header">
						<h2 className="section-title">MEET OUR TEAM</h2>
					</div>
					<Employee isLoading={false} employeeList={employeeList} />
				</div>
			</section>
		</div>
	);
}

export default EmployeeDisplay;
